import {
    get,
    post
} from '../utils/http';

// 获取工作提醒
export function getGZTXPageList(data) {
  return get('fdy/SY_FDY_GZTX/GetPageList/', data);
}

// 保存工作提醒
export function saveGZTX(data) {
  return post('fdy/SY_FDY_GZTX/Save/', data);
}

// 获取工作提醒
export function getGZTXFormData(data) {
  return get('fdy/SY_FDY_GZTX/GetFormData/', data);
}

// 删除工作提醒
export function deleteGZTX(data) {
  return get('fdy/SY_FDY_GZTX/Delete/', data);
}

// 获取工作提醒
export function getGZTXListToIndex() {
  return get('fdy/SY_FDY_GZTX/GetListToIndex/');
}
